import { BehaviorSubject, catchError, lastValueFrom, map, Observable, retry, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { ConstantsService } from './constants.service';

import { Settings } from '../pages/settings/settings';
import { SettingTypes } from '../pages/settings/setting-types';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  settings: BehaviorSubject<Settings>;

  types: SettingTypes = {
    JACKPOT_AMOUNT: 'jackpot_amount',
    PRICE_PER_GAME: 'price_per_game',
    PRICE_PER_USER: 'price_per_user',
    SONG_INFO_BTN_INTERVAL: "song_info_btn_interval",
    JACKPOT_THRESHOLD: 'jackpot_threshold',
    JACKPOT_REQUIRED_SONG_COUNT: 'jackpot_required_song_count',
    A_IF_YOU_CAN_HEAR_THIS: 'a_if_you_can_hear_this',
    B_10_MINUTES: 'b_10_minutes',
    C_5_MINUTES: 'c_5_minutes',
    D_ROUND_1_START: 'd_round_1_start',
    E_ROUND_2_START: 'e_round_2_start',
    F_ROUND_3_START: 'f_round_3_start',
    G_5_SONGS_LEFT: 'g_5_songs_left',
    H_2_SONGS_LEFT: 'h_2_songs_left',
    I_1_SONG_LEFT: 'i_1_song_left',
    J_NOBODY_WON: 'j_nobody_won',
    K_WE_HAVE_A_WINNER: 'k_we_have_a_winner',
    L_WE_HAVE_A_JACKPOT_WINNER: 'l_we_have_a_jackpot_winner',
    M_END_OF_GAME_ANOTHER_GAME_COMING: 'm_end_of_game_another_game_coming',
    N_END_OF_LAST_GAME: 'n_end_of_last_game',
    SIRIUS: 'sirius'
  };

  constructor(
    private http: HttpClient,
    private constants: ConstantsService,
  ) {
    this.settings = new BehaviorSubject<Settings>({});
  }

  /**
   * Gets all settings from the server
   * The response is an object with the key being the property (matches types above)
   * and the value is the value of the property
   */
  getAll(): Observable<Settings> {
    console.log('GETTING SETTINGS')
    const url = `${this.constants.API_BASE_URL}/settings`;

    return this.http.get<{ settings: Settings }>(url).pipe(
      retry(3),
      map(response => {
        this.settings.next(response.settings)
        return response.settings
      }),
      catchError(this.handleHttpError)
    )
  }

  // Not sure why we have two of these... same as getAll()
  getAllOnce(): Observable<Settings> {
    const url = `${this.constants.API_BASE_URL}/settings`;

    return this.http.get<{ settings: Settings }>(url).pipe(
      retry(3),
      map(response => response.settings),
      catchError(this.handleHttpError)
    )
  }

  /**
   * Helper function to check if settings are empty.
   */
  private isSettingsEmpty(settings: Settings): boolean {
    // Assuming settings is an object with properties you can check
    // You can modify this check based on how the Settings model is structured.
    return Object.keys(settings).length === 0;
  }

  private handleHttpError(error: HttpErrorResponse) {
    console.error('HTTP Error:', error);
    return throwError(() => new Error(error.message || 'An unknown error occurred'));
  }
}

// Old requests using promises instead of observables
/*async getAll(): Promise<void> {
  const fakeError = new HttpErrorResponse({
    error: 'Simulated network error',
    status: 0,
    statusText: 'Unknown Error',
  });

  return await lastValueFrom(
    throwError(() => fakeError).pipe(
      catchError((error) => {
        console.error('Fake network error triggered:', error);
        return throwError(() => new Error());
      })
    )
  );
  const url = `${this.constants.API_BASE_URL}/settings`;
  const response = await this.http.get<{ settings: Settings }>(url).toPromise();
  this.settings.next(response.settings);
}




async getAllOnce(): Promise<any> {
  const fakeError = new HttpErrorResponse({
    error: 'Simulated network error',
    status: 0,
    statusText: 'Unknown Error',
  });

  return await lastValueFrom(
    throwError(() => fakeError).pipe(
      catchError((error) => {
        console.error('Fake network error triggered:', error);
        return throwError(() => new Error());
      })
    )
  );

  const url = `${this.constants.API_BASE_URL}/settings`;
  const response = await this.http.get<{ settings: Settings }>(url).toPromise();
  return response.settings;
}*/

